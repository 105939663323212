define("@trixie-html/addon-utils/helpers/strip-html", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stripHtml = stripHtml;
  /**
   * Remove HTML and formatting from text
   *
   * @example
   *     stripHtml('<script>alert('Ron Arehucas')</script>')
   *     returns 'alert('Ron Arehucas')'
   *
   * @method stripHtml
   * @param {String} input
   * @param {String} allowed
   * @return {String}
   *
   */

  function stripHtml(input) {
    let allowed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    allowed = (String(allowed).toLowerCase().match(/<[a-z][\da-z]*>/g) || []).join('');
    const tags = /<\/?([a-z][\da-z]*)\b[^>]*>/gi;
    const commentsAndPhpTags = /<!--[\S\s]*?-->|<\?(?:php)?[\S\s]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
    // eslint-disable-next-line no-confusing-arrow
    .replace(tags, ($0, $1) => allowed.indexOf("<".concat($1.toLowerCase(), ">")) > -1 ? $0 : '').trim();
  }
  var _default = (0, _helper.helper)({
    compute(args) {
      return stripHtml(...args);
    }
  });
  _exports.default = _default;
});